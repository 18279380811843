import React, { useEffect, useState } from 'react'
import Navbar from '../Components/Navbar'
import { useParams } from 'react-router-dom'
import CategoryDetailHeader from '../Components/CategoryDetailHeader';
import '../Styles/CategoryDetail.css'
import CategoryCards from '../Components/CategoryCards';
import Footer from '../Components/Footer'
import { CategoryProductList } from '../CategoryProductList';
import ElevatorCategoryThumbnail from '../assests/ElevatorCategoryThumbnail.jpg'
import EscalatorCategoryThumbnail from '../assests/EscalatorCategoryThumbnail.jpg'
import Loader from '../Components/Loader';


const CategoryProducts = [
  {
    name: 'Escalator',
    description: 'Designed specifically for commercial use, the Platinum Commercial Escalator offers robust performance and durability. It is engineered to withstand heavy foot traffic in malls, airports, and other commercial settings.',
    category: 'escalators',
    thumbnail: EscalatorCategoryThumbnail
  },
  {
    name: 'Elevators',
    description: "Elevators play a crucial role in vertical transportation. Whether it's providing comfort and convenience in residential villas, ensuring smooth and efficient transit for passengers, or facilitating the movement of heavy goods and cargo, our elevators are engineered to meet diverse requirements.",
    category: 'elevators',
    thumbnail: ElevatorCategoryThumbnail
  },
  {
    name: 'Moving Walkway',
    description: 'The Skyline Moving Walkway provides efficient pedestrian transportation for airports, shopping centers, and exhibition halls. Its sleek design and smooth operation enhance the overall mobility experience for users in high-traffic environments.',
    category: 'moving-walks',
    thumbnail: 'https://images.pexels.com/photos/5092826/pexels-photo-5092826.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
  }
];


const CategoryDetail = () => {
  let { categoryName } = useParams();
  const [activeCategory, setActiveCategory] = useState('Residential/Villa');

  useEffect(() => {
    document.title = `Our ${categoryName} | OSQA Elevators And Escalators Pvt. Ltd. `;
  }, [categoryName]);

  useEffect(() => {
    if (categoryName === 'escalators') {
      setActiveCategory("Commercial")
    } else if (categoryName === 'elevators') {
      setActiveCategory("Residential/Villa")
    } else {
      setActiveCategory('Moving-Walk')
    }
  }, [categoryName]);

  const handleClick = (category) => {
    setActiveCategory(category);
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);




  return (
    <>
      {loading ? <Loader />
        :
        <>
          <Navbar />
          {
            categoryName === 'elevators' ?
              <>
                <div className="CategoryDetailHeaderWrapper">
                  <CategoryDetailHeader activeProduct={CategoryProducts.filter((product) => product.category === categoryName)} />
                </div>
                <div className="ProductCategoryNavWrap">
                  <div className="ProductCategoryNav">
                    <span className={activeCategory === 'Residential/Villa' ? 'active' : ''} onClick={() => handleClick('Residential/Villa')}>
                      Residential/Villa
                    </span>
                    <span className={activeCategory === 'Passenger' ? 'active' : ''} onClick={() => handleClick('Passenger')}>
                      Passenger
                    </span>
                    <span className={activeCategory === 'Freight' ? 'active' : ''} onClick={() => handleClick('Freight')}>
                      Freight
                    </span>
                  </div>
                </div>
                <CategoryCards data={CategoryProductList.filter(category => category.slug === activeCategory)} />
              </> : categoryName === "escalators" ?
                <>
                  <div className="CategoryDetailHeaderWrapper">
                    <CategoryDetailHeader activeProduct={CategoryProducts.filter((product) => product.category === categoryName)} />
                  </div>
                  <div className="ProductCategoryNavWrap">
                    <div className="ProductCategoryNav">
                      <span className={activeCategory === 'Commercial' ? 'active' : ''} onClick={() => handleClick('Commercial')}>
                        Commercial
                      </span>
                      <span className={activeCategory === 'Heavy-Duty' ? 'active' : ''} onClick={() => handleClick('Heavy-Duty')}>
                        Heavy Duty
                      </span>
                    </div>
                  </div>
                  <CategoryCards data={CategoryProductList.filter(category => category.slug === activeCategory)} />
                </>
                :
                <>
                  <div className="CategoryDetailHeaderWrapper">
                    <CategoryDetailHeader activeProduct={CategoryProducts.filter((product) => product.category === categoryName)} />
                  </div>
                  <CategoryCards data={CategoryProductList.filter(category => category.slug === activeCategory)} />

                </>
          }
          <Footer />
        </>
      }
    </>
  )
}

export default CategoryDetail