import React from 'react';
import './Footer.css'
import logo from '../assests/osqa-logo.png'
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="footer-section">
            <div className="container">
                <div className="footer-content pt-5 pb-5">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 mb-50 logoAndText">
                            <div className="footer-widget">
                                <div className="footer-logo">
                                    <a href="/">
                                        <img src={logo} className="img-fluid" alt="logo" />
                                    </a>
                                </div>
                                <div className="footer-text">
                                    <p>Partnering with UK-based Hansons, known for integrating Korean technology, positions us as the top choice for premium escalators and elevators. </p>
                                </div>
                            </div>
                        </div>
                        <div className="footer-links">
                            <div className="footer-widget">
                                <div className="footer-widget-heading">
                                    <h3>Company</h3>
                                </div>
                                <ul>
                                    <li><Link to='/'>Home</Link></li>
                                    <li><Link to='/about'>About</Link></li>
                                    <li><Link to='/products'>Products</Link></li>
                                    <li><Link to='/contact'>Contact</Link></li>
                                </ul>
                            </div>
                            <div className="footer-widget">
                                <div className="footer-widget-heading">
                                    <h3>Products</h3>
                                </div>
                                <ul>
                                    <li><Link to='/products/elevators'>Elevator</Link></li>
                                    <li><Link to='/products/escalators'>Escalator</Link></li>
                                    <li><Link to='/products/moving-walks'>Moving Walk</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 mb-50">
                            <div className="footer-social-icon">
                                <div className="footer-widget-heading">
                                    <h3>Follow us</h3>
                                </div>
                                <div className="footer-social-iscons">
                                    <a href="#"><i className="fab fa-facebook-f facebook-bg"></i></a>
                                    <a href="#"><i className="fab fa-twitter twitter-bg"></i></a>
                                    <a href="#"><i className="fab fa-google-plus-g google-bg"></i></a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="copyright-area">
                <div className="footer-line">

                </div>
                <div className="copyright-text">
                    <p>Copyright &copy; 2024, All Right Reserved <Link to='/'>OSQA</Link></p>
                </div>

            </div>
        </footer>
    );
}

export default Footer;
