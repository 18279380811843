import React, { useState, useEffect } from 'react';
import Navbar from '../Components/Navbar';
import about from '../assests/escalator-with-orange-steps-shiny-metal-sides.jpg';
import engineer from '../assests/Untitled design.png'
import "../Styles/About.css"
import Footer from '../Components/Footer';
import Breadcrumbs from '../Components/BreadCrupm';
import Loader from '../Components/Loader';


const AboutPage = () => {
  useEffect(() => {
    document.title = "About Us | OSQA Elevators And Escalators Pvt. Ltd. ";
  }, []);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);



  return (
    <>
      {loading ? <Loader /> :
        <>
          <Navbar />
          {/* <Breadcrumbs paths={breadcrumbs} /> */}
          <div className='aboutUsWrapperImg' style={{ position: 'relative', textAlign: 'center' }}>
            <img className='' src='https://images.pexels.com/photos/768562/pexels-photo-768562.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' alt="About Us" style={{ width: '100%', height: '350px' }} />
            <div className="overlay" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
              <div style={{ fontSize: '3rem', fontWeight: 'bold', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>
                About Us
              </div>
            </div>
          </div>
          <div className="AboutUsWrapper">
            <div className="AboutUsWrap">
              <div className="WhoWeAreWrap">
                <div className="WhoWeAreWrapLeft">
                  <h1>Who we are ?</h1>
                </div>
                <div className="WhoWeAreWrapRight">
                  <p>We are OSQA ELEVATORS AND ESCALATORS PRIVATE LIMITED, a leading provider of high-quality escalators and elevators. With a focus on excellence, we take pride in our ability to manufacture and import top-tier escalators and elevators that meet the highest standards of quality and safety. Through strategic partnerships with renowned brands like Hansons and Laiao Elevators, we ensure that our products are engineered with the latest technology and expertise, offering solutions that excel in performance, reliability, and innovation.
                  </p>
                </div>
              </div>
              <div className="MissionVisionWrap">
                <div className="VisionWrap">
                  <h1>Our Vision</h1>
                  <p>
                    Lead globally in elevators and escalators. Seamlessly connect people, floors, destinations. Integrate sustainability, safety, aesthetics. Transform urban landscapes. OSQA Elevators & Escalators: Innovate, collaborate, elevate experiences.
                  </p>
                </div>
                <div className="MissionWrap">
                  <h1>Our Mission</h1>
                  <p>
                    Elevate lives with safe, reliable, innovative vertical transportation. Enhance mobility, convenience, accessibility. Redefine how people move with technology, craftsmanship, dedication.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="teamWrapper">
          <div className="teamwrap">
            <h1>Meet Our <br />amazing Team</h1>
            <hr />
            <div className="card-container">
              <div className="card">
                <img src="https://images.pexels.com/photos/1222271/pexels-photo-1222271.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="Team Member 1" />
                <h2>John Doe</h2>
                <p>Position</p>
              </div>
              <div className="card">
                <img src="https://images.pexels.com/photos/1222271/pexels-photo-1222271.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="Team Member 2" />
                <h2>Jane Smith</h2>
                <p>Position</p>
              </div>
              <div className="card">
                <img src="https://images.pexels.com/photos/1222271/pexels-photo-1222271.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="Team Member 3" />
                <h2>David Johnson</h2>
                <p>Position</p>
              </div>
              <div className="card">
                <img src="https://clipart-library.com/images/8TxrBGznc.jpg" alt="Team Member 4" />
                <h2>Could be you</h2>
                <p>Apply now</p>
              </div>
            </div>
          </div>
        </div> */}
          <div className="haveQueWrapper">
            <div className="haveQueWrap">
              <div className="haveQueLeft">
                <h1>Have a question ?</h1>
                <h1>Our team is happy to assist you</h1>
                <p>Ask about ASQA products, pricing, installation, or anything else. Our highly trained representetives are standing by ready to help.</p>
                <hr />
                <div className="aboutCallToAction">
                  <a href="/hehehe" className='aboutCallToActionContact'>
                    <span>Contact Us</span>
                    <span><i class="fa-solid fa-greater-than"></i></span>
                  </a>
                  <a href="#" className='aboutPhoneNo'>Or call +91 8744968725</a>
                </div>
              </div>
              <div className="haveQueRight">
                <img src={engineer} alt="" />
              </div>
            </div>
          </div>
          <Footer />
        </>
      }
    </>

  );
};

export default AboutPage;
