import React, { useState, useEffect } from 'react';
import '../Styles/Loader.css';

const Loader = () => {

    return (
        <div className="loader-wrapper">
            <div className="elevator-wrapper">
                <div className="elevator">
                    <div className="doors">
                        <div className="left-door"></div>
                        <div className="right-door"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Loader;
