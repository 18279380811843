import React, { useState, useEffect } from 'react'
import Navbar from '../Components/Navbar'
import ProductCards from '../Components/ProductCards'
import Footer from '../Components/Footer'
import video from '../assests/video.mp4'
import Loader from '../Components/Loader'

const ProductsPage = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.title = "Our Products | OSQA Elevators And Escalators Pvt. Ltd.";
      }, []);
    

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);


    return (
        <>
            {loading ? <Loader />
                :
                <>
                    <Navbar />
                    <div className="hero-container">
                        <div className="video-background">
                            <video autoPlay muted loop>
                                <source src={video} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                        <div className="overlayy">
                            <h1>Elevate Your Experience</h1>
                            <p>Discover our innovative elevator, escalator, and moving walk solutions</p>
                        </div>
                    </div>
                    <ProductCards />
                    <Footer />
                </>
            }
        </>
    )
}

export default ProductsPage