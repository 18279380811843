import FreightElevatorOne from './assests/freight-elevator-1.jpg'
import FreightElevatorTwo from './assests/freight-elevator-2.jpg'
import FreightElevatorThree from './assests/freight-elevator-3.jpg'
import FreightElevatorFour from './assests/freight-elevator-4.jpg'
import VillaElevatorOne from './assests/villa-elevator-1.jpg'
import VillaElevatortwo from './assests/villa-elevator-2.jpg'
import VillaElevatorThree from './assests/villa-elevator-3.jpg'
import VillaElevatorFour from './assests/villa-elevator-4.jpg'
import VillaElevatorFive from './assests/villa-elevator-5.jpg'
import VillaElevatorSix from './assests/villa-elevator-6.jpg'
import PassengerElevatorOne from './assests/passenger-elevator-1.jpg'
import PassengerElevatorTwo from './assests/passenger-elevator-2.jpg'
import PassengerElevatorThree from './assests/passenger-elevator-3.jpg'
import PassengerElevatorFour from './assests/passenger-elevator-4.jpg'
import PassengerElevatorFive from './assests/passenger-elevator-5.jpg'
import PassengerElevatorSix from './assests/passenger-elevator-6.jpg'
import MovingWalksOne from './assests/moving-walks-1.png'
import MovingWalksTwo from './assests/moving-walks-2.png'
import MovingWalksThree from './assests/moving-walks-3.png'
import heavyDutyEscalatorOne from './assests/heavy-duty-escalator-1.webp'
import heavyDutyEscalatorTwo from './assests/heavy-duty-escalator-2.webp'
import heavyDutyEscalatorThree from './assests/heavy-duty-escalator-3.jpg'
import heavyDutyEscalatorFour from './assests/heavy-duty-escalator-4.jpg'
import commercialEscalatorOne from './assests/commercial-escalator-1.jpg'
import commercialEscalatorTwo from './assests/commercial-escalator-2.jpg'
import commercialEscalatorThree from './assests/commercial-escalator-3.jpg'
import commercialEscalatorFour from './assests/commercial-escalator-4.jpeg'
import commercialEscalatorFive from './assests/commercial-escalator-5.jpeg'









export const CategoryProductList = [
    {
        name: 'Villa Elevator',
        slug: 'Residential/Villa',
        thumbnail: VillaElevatorOne
    },
    {
        name: 'Villa Elevator',
        slug: 'Residential/Villa',
        thumbnail: VillaElevatortwo
    },
    {
        name: 'Villa Elevator',
        slug: 'Residential/Villa',
        thumbnail: VillaElevatorThree
    },
    {
        name: 'Villa Elevator',
        slug: 'Residential/Villa',
        thumbnail: VillaElevatorFour
    },
    {
        name: 'Villa Elevator',
        slug: 'Residential/Villa',
        thumbnail: VillaElevatorFive
    },
    {
        name: 'Villa Elevator',
        slug: 'Residential/Villa',
        thumbnail: VillaElevatorSix
    },
    {
        name: 'Passenger Elevator',
        slug: 'Passenger',
        thumbnail: PassengerElevatorOne
    },
    {
        name: 'Passenger Elevator',
        slug: 'Passenger',
        thumbnail: PassengerElevatorTwo

    },
    {
        name: 'Passenger Elevator',
        slug: 'Passenger',
        thumbnail: PassengerElevatorThree

    },
    {
        name: 'Passenger Elevator',
        slug: 'Passenger',
        thumbnail: PassengerElevatorFour

    },
    {
        name: 'Passenger Elevator',
        slug: 'Passenger',
        thumbnail: PassengerElevatorFive

    },
    {
        name: 'Passenger Elevator',
        slug: 'Passenger',
        thumbnail: PassengerElevatorSix

    },
    {
        name: 'Freight Elevator',
        slug: 'Freight',
        thumbnail: FreightElevatorFour
    },
    {
        name: 'Freight Elevator',
        slug: 'Freight',
        thumbnail: FreightElevatorThree
    },
    {
        name: 'Freight Elevator',
        slug: 'Freight',
        thumbnail: FreightElevatorTwo
    },
    {
        name: 'Freight Elevator',
        slug: 'Freight',
        thumbnail: FreightElevatorOne
    },
    {
        name: 'Commercial Escalator',
        slug: 'Commercial',
        thumbnail:commercialEscalatorOne
    },
    {
        name: 'Commercial Escalator',
        slug: 'Commercial',
        thumbnail:commercialEscalatorTwo
    },
    {
        name: 'Commercial Escalator',
        slug: 'Commercial',
        thumbnail:commercialEscalatorThree
    },
    {
        name: 'Commercial Escalator',
        slug: 'Commercial',
        thumbnail:commercialEscalatorFour
    },
    {
        name: 'Commercial Escalator',
        slug: 'Commercial',
        thumbnail:commercialEscalatorFive
    },
    {
        name: 'Heavy Duty Escalator',
        slug: 'Heavy-Duty',
        thumbnail: heavyDutyEscalatorTwo
    },
    {
        name: 'Heavy Duty Escalator',
        slug: 'Heavy-Duty',
        thumbnail: heavyDutyEscalatorFour
    },
    {
        name: 'Heavy Duty Escalator',
        slug: 'Heavy-Duty',
        thumbnail: heavyDutyEscalatorOne
    },
    
    {
        name: 'Heavy Duty Escalator',
        slug: 'Heavy-Duty',
        thumbnail: heavyDutyEscalatorThree
    },
    
    {
        name: 'Moving Walk',
        slug: 'Moving-Walk',
        thumbnail: MovingWalksOne
    },
    {
        name: 'Moving Walk',
        slug: 'Moving-Walk',
        thumbnail: MovingWalksTwo
    },
    {
        name: 'Moving Walk',
        slug: 'Moving-Walk',
        thumbnail: MovingWalksThree
    },
];
