import React from 'react';
import { Link } from 'react-router-dom';

const CategoryDetailHeader = ({ activeProduct }) => {
  return (
    <div className='CategoryDetailHeader' style={{ backgroundImage: `url(${activeProduct[0].thumbnail})` }}>
      <div className="CategoryDetailHeaderOverlay">
        <div className="CategoryDetailHeaderText">
          <h1>{activeProduct[0].name}</h1>
          <p>{activeProduct[0].description}</p>
        </div>
      </div>
    </div>
  );
}

export default CategoryDetailHeader;
