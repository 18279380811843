import React, { useState, useEffect } from 'react'
import Navbar from '../Components/Navbar'
import map from '../assests/map.png'
import Footer from '../Components/Footer'
import '../Styles/Contact.css'
import Loader from '../Components/Loader'
import emailjs from '@emailjs/browser';
import swal from 'sweetalert'


const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    reason: 'general',
    message: ''
  });
  useEffect(() => {
    document.title = "Contact Us | OSQA Elevators And Escalators Pvt. Ltd.";
  }, []);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const { name, email, phone, reason, message } = formData;
  
    if (!name || !email || !message) {
      alert("Please fill out all required fields.");
      return;
    }
  
    const serviceId = "service_5mnarqp"
    const templateId = "template_p0fnfwf"
    const publicKey = "EInuoDCy7ZwqbDR6b"
    emailjs.send(serviceId, templateId, { name, email, phone, reason, message }, publicKey)
      .then((response) => {
        setFormData({
          name: '',
          email: '',
          phone: '',
          reason: 'general',
          message: ''
        });
        swal({
          title: "Success",
          text: "You Message Have Been Successfully, Our Team Will Contact You Soon.",
          icon: "success",
          customClass: {
            popup: 'swal-center' 
          }
        });
          ;
      })
      .catch((error) => {
        alert("An error occurred while sending the email. Please try again later.");
      });
  };
  

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);


  return (
    <>
      {loading ? <Loader />
        :
        <>
          <Navbar />
          <div className="contactWrapper">
            <div className="contactWrap">
              <h1>Love to hear from you, <br /> Get in touch 👋</h1>
              <div className="contactFlex">
                <div className="contactFlexLeft">
                  <p className="name">
                    OSQA Elevators And Esclators Private Limited
                  </p>
                  <p className="address">
                    GF - 30, TDI Center, Plot No - 07, District Centre Jasola, New Delhi - 110025
                  </p>
                  <img src={map} alt="" />
                  <div className="PhoneAndEmail">
                    <div className="Phone">
                      <p className="callUs">Call Us</p>
                      <p className="callUsNo">+91 8744968725 / +91 7065705747</p>
                    </div>
                    <div className="Email">
                      <p className="emailUs">Email Us</p>
                      <p className="email">info@osqa.in</p>
                    </div>
                  </div>
                </div>
                <div className="contactFlexRight">
                  <div className="contact-form">
                    <form>
                      <div className="contact-field">
                        <label htmlFor="name">Your Name</label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          placeholder="Enter your name"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="contact-field">
                        <label htmlFor="email">Email Address</label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Enter your email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="contact-field">
                        <label htmlFor="phone">Phone Number</label>
                        <input
                          type="tel"
                          id="phone"
                          name="phone"
                          placeholder="Enter your phone number"
                          value={formData.phone}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="contact-field">
                        <label htmlFor="reason">Reason for Contact</label>
                        <select
                          id="reason"
                          name="reason"
                          value={formData.reason}
                          onChange={handleChange}
                        >
                          <option value="general">General Inquiry</option>
                          <option value="support">Customer Support</option>
                          <option value="feedback">Feedback</option>
                          <option value="other">Other</option>
                        </select>
                      </div>
                      <div className="contact-field">
                        <label htmlFor="message">Message</label>
                        <textarea
                          id="message"
                          name="message"
                          rows="2"
                          placeholder="Enter your message"
                          value={formData.message}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                      <div className="contact-field">
                        <button type="submit" onClick={handleSubmit}>
                          <span>Submit</span>
                          <i class="fa-solid fa-arrow-right-long"></i>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>}
    </>
  )
}

export default ContactPage