import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { HiMenu } from 'react-icons/hi';
import { RxCross2 } from 'react-icons/rx';
import logo from '../assests/osqa-logo.png';

const Navbar = () => {
    const [isActive, setIsActive] = useState(false);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const toggleActiveClass = () => {
        setIsActive(!isActive);
    };

    const removeActive = () => {
        setIsActive(false);
    };

    const togglePopover = () => {
        setIsPopoverOpen(!isPopoverOpen);
    };

    const handlePopoverMouseEnter = () => {
        setIsPopoverOpen(true);
    };

    const handlePopoverMouseLeave = () => {
        setIsPopoverOpen(false);
    };

    const handlePopoverItemClick = () => {
        setIsPopoverOpen(true);
    };

    return (
        <div className="navContainer">
            <div className='navbar'>
                <div className="logo">
                    <img src={logo} alt="OSQA Logo" />
                </div>
                <div className='navMenuWrap'>
                    <ul className='navMenu'>
                        <li><NavLink to='/' onClick={removeActive}>Home</NavLink></li>
                        <li><NavLink to='/about' onClick={removeActive}>About Us</NavLink></li>
                        <li
                            onMouseEnter={togglePopover}
                            onMouseLeave={togglePopover}
                            className="productsItem"
                        >
                            <NavLink to='/products'>Products</NavLink>
                            {isPopoverOpen && (
                                <div
                                    className="popover"
                                    onMouseEnter={handlePopoverMouseEnter}
                                    onMouseLeave={handlePopoverMouseLeave}
                                >
                                    <ul onClick={handlePopoverItemClick}>
                                        <li><NavLink to='/products/elevators'>Elevators</NavLink></li>
                                        <li><NavLink to='/products/escalators'>Escalators</NavLink></li>
                                        <li><NavLink to='/products/moving-walks'>Movings Walks</NavLink></li>
                                    </ul>
                                </div>
                            )}
                        </li>
                        <li><NavLink to='/contact' onClick={removeActive}>Contact Us</NavLink></li>
                    </ul>
                    <button className='HammBurger' onClick={toggleActiveClass}>
                        {isActive ? <RxCross2 /> : <HiMenu />}
                    </button>
                </div>
                <div className={`mobileNavWrapper ${isActive ? 'active' : ''}`}>
                    <button className='close HammBurger' onClick={removeActive}><RxCross2 /></button>
                    <ul className={`MobileNavMenu  ${isActive ? 'active' : ''}`}>
                        <li onClick={removeActive}><NavLink to='/'>Home</NavLink></li>
                        <li onClick={removeActive}><NavLink to='/about'>About Us</NavLink></li>
                        <li
                            onMouseEnter={togglePopover}
                            onMouseLeave={togglePopover}
                            className="productsItem"
                        >
                            <NavLink to='/products'>Products</NavLink>
                        </li>
                        <li onClick={removeActive}><NavLink to='/contact'>Contact Us</NavLink></li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
