import React, { useState, useEffect } from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import ProductCards from '../Components/ProductCards';
import '../Styles/Home.css';
import heroVideo from '../assests/Home_Hero.mp4';
import { FaArrowRightLong, FaArrowLeftLong } from "react-icons/fa6";
import { Link } from 'react-router-dom'
import Loader from '../Components/Loader';
import mileStonesBG from '../assests/milestones-bg.jpg'

const slidesData = [
  {
    id: 1,
    title: 'Crafting Excellence in Escalator and Elevator Manufacturing and Designing State-of-the-Art Solutions',
    tagline: 'Elevating Innovation, Together',
    description: 'We utilize cutting-edge technology to craft state-of-the-art escalators, elevators, and moving walks. Our commitment to excellence drives us to continuously innovate and deliver products that redefine industry standards. With a focus on quality, safety, and reliability, we strive to exceed our customers\' expectations.'
  },
  {
    id: 2,
    title: 'Seamless Installation Services for Commercial and Residential Settings with Expert Precision',
    tagline: 'Redefining Convenience, Side by Side',
    description: 'Our expert team ensures seamless installation of escalators and elevators in various commercial and residential settings. From planning and design to final execution, we handle every step of the installation process with precision and care. With years of experience and technical expertise, we guarantee a smooth and hassle-free installation experience for our clients.'
  },
  {
    id: 3,
    title: 'Reliable Maintenance Plans for Smooth Operation and Long-Term Performance Assurance',
    tagline: 'Keeping You Moving, Safely',
    description: 'With proactive maintenance plans and responsive support, we keep your escalators and elevators running smoothly. Our team of skilled technicians conducts regular inspections and preventive maintenance to identify and address potential issues before they escalate. We understand the importance of uptime and strive to minimize downtime through timely maintenance and efficient servicing.'
  },
  {
    id: 4,
    title: 'Prioritizing Safety in Every Aspect of Escalator and Elevator Lifecycle Management',
    tagline: 'Safety First, Every Step of the Way',
    description: 'We adhere to strict safety standards to ensure the well-being of passengers and employees. Safety is our top priority, and we implement rigorous safety protocols throughout every stage of the escalator and elevator lifecycle. From design and manufacturing to installation and maintenance, we prioritize safety to provide a secure and comfortable transportation experience.'
  },
  {
    id: 5,
    title: 'Customization Options Tailored to Your Unique Needs and Elevating Your Space',
    tagline: 'Your Vision, Our Innovation',
    description: 'Explore our wide range of customization options to tailor escalators and elevators to your specific needs and preferences. Whether you require unique design elements, enhanced accessibility features, or advanced technology integration, we offer customizable solutions to meet your requirements. Our flexible approach ensures that your escalator or elevator reflects your vision and enhances your space.'
  },
  {
    id: 6,
    title: 'Exceptional Customer Service for Total Satisfaction and Seamless Experience',
    tagline: 'Your Journey, Our Commitment',
    description: 'At our company, we pride ourselves on providing exceptional service to our clients. From the initial consultation to ongoing support, we are dedicated to exceeding customer expectations at every touchpoint. Our knowledgeable team is always available to address your questions, concerns, and needs, ensuring a seamless experience and complete satisfaction.'
  },
];


const HomePage = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    document.title = "OSQA Elevators And Escalators Pvt. Ltd. | Home ";
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide === slidesData.length - 1 ? 0 : prevSlide + 1));
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const goToPreviousSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === 0 ? slidesData.length - 1 : prevSlide - 1));
  };

  const goToNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === slidesData.length - 1 ? 0 : prevSlide + 1));
  };
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2500);

    return () => clearTimeout(timer);
  }, []);


  return (
    <>
      {
        loading ? <Loader />
          :
          <>
            <Navbar />
            <div className="heroSection">
              <video className="backgroundVideo" autoPlay loop muted>
                <source src={heroVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className="hero-slider heroOverlayTextWrapper">
                <div className="heroOverlayText">
                  {slidesData.map((slide, index) => (
                    <div key={slide.id} className={`slide ${index === currentSlide ? 'active' : ''}`}>
                      <div className="slide-content">
                        <span className="tagline">{slide.tagline}</span>
                        <h1>{slide.title}</h1>
                        {/* <p>{slide.description}</p> */}
                        <Link to={`/contact`} className="homeHeroButton">
                          <span>Get In Touch</span>
                          <i class="fa-solid fa-arrow-right-long"></i>
                        </Link>
                      </div>
                      <div className="custom-button">
                        <button onClick={goToPreviousSlide}>< FaArrowLeftLong /></button>
                        <button onClick={goToNextSlide}><FaArrowRightLong /></button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className='milestonesWrapper' style={{ backgroundImage: `url(${mileStonesBG})` }}>
              <div className="milestonesWrapperOverlay">
                <h1 className='milestonesHeading'>Milestones, We Have Achieved ✌️</h1>
                <div class="milestoneCards">
                  <div class="card">
                    <h3>4+</h3>
                    <p>Years Of Experience</p>
                  </div>
                  <div class="card">
                    <h3>14+</h3>
                    <p>Number Of Clients</p>
                  </div>
                  <div class="card">
                    <h3>16+</h3>
                    <p>Number Of Projects</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="whyASQAWrapper">
              <div className="whyASQAWrap">
                <div className="whyDivOne">
                  <div className="whyCardOne">
                    <h1>Why OSQA ?</h1>
                    <p>At OSQA, we stand out as the premier choice for all your escalator, elevator, and moving walk needs. Here's why discerning clients like you should choose us:</p>
                  </div>
                  <div className="whyCardTwo">
                    <h1>Expertise</h1>
                    <p>With decades of experience, we excel in manufacturing top-of-the-line escalators, elevators, and moving walks. Our skilled engineers and technicians deliver products of exceptional quality and reliability.</p>
                  </div>
                  <div className="whyCardThree">
                    <h1>Innovation</h1>
                    <p>Committed to innovation, we leverage cutting-edge technology to create products that meet the highest standards of safety, efficiency, and performance.</p>
                  </div>
                </div>
                <div className="whyDivTwo">
                  <div className="whyDivTwoCardOne">
                    <h1>Seamless Installation</h1>
                    <p>Our expert team ensures precise and efficient installation, handling projects of all sizes with guaranteed seamless experiences from start to finish.</p>
                  </div>
                  <div className="whyDivTwoCardTwo">
                    <h1>Responsive Maintenance</h1>
                    <p>With proactive maintenance plans and round-the-clock support, we keep your vertical transportation systems in optimal condition, minimizing downtime and maximizing uptime.</p>
                  </div>
                  <div className="whyDivTwoCardThree">
                    <h1>Customer-Centric Approach</h1>
                    <p>Customer satisfaction is our top priority. We provide personalized attention, transparent communication, and prompt assistance, building long-lasting relationships with our clients.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="topProductsWrapper">
              <p>Explore Our Top Products</p>
              <div className="topProductsLine"></div>
            </div>
            <ProductCards />
            <Footer />
          </>
      }
    </>
  );
};

export default HomePage;
