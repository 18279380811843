import React from 'react';
import { Link } from 'react-router-dom';

const ProductCards = () => {
    // Sample data for product cards
    const products = [
        {
            title: 'Elevators',
            slug: 'elevators',
            description: 'Our elevators feature advanced technology and sleek design, ensuring smooth and efficient vertical transportation.',
            imageUrl: 'https://images.pexels.com/photos/1281346/pexels-photo-1281346.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' // Replace 'elevators.jpg' with the path to your image
        },
        {
            title: 'Escalators',
            slug: 'escalators',
            description: 'Experience the convenience and reliability of our escalators, designed to provide seamless movement in busy environments.',
            imageUrl: 'https://images.pexels.com/photos/2087514/pexels-photo-2087514.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' // Replace 'escalators.jpg' with the path to your image
        },
        {
            title: 'Moving Walks',
            slug: 'moving-walks',
            description: 'Enhance pedestrian mobility with our moving walks, offering safe and efficient transportation in public spaces and buildings.',
            imageUrl: 'https://images.pexels.com/photos/5922835/pexels-photo-5922835.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' // Replace 'moving_walks.jpg' with the path to your image
        }
    ];

    return (
        <section className="product-cards">
            <div className="container">
                {products.map((product, index) => (
                    <div className="product-card" key={index}>
                        <img src={product.imageUrl} alt={product.title} />
                        <div className="product-info">
                            <h2>{product.title}</h2>
                            <p>{product.description}</p>
                            <Link to={`/products/${product.slug}`} className="explore-button">
                                <span>Explore Now</span>
                                <i class="fa-solid fa-arrow-right-long"></i>
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default ProductCards;
