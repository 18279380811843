import React, { useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import HomePage from './Pages/HomePage'
import ContactPage from './Pages/ContactPage'
import AboutPage from './Pages/AboutPage'
import ProductsPage from './Pages/ProductsPage'
import CategoryDetail from './Pages/CategoryDetail'

const App = () => {
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    fetch('https://staging.momkidcare.com/get-opacity')
      .then(response => response.json())
      .then(data => {
        setOpacity(data.opacity);
      })
      .catch(error => {
        console.error('Error fetching opacity:', error);
      });
  }, []);

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="products" element={<ProductsPage />} />
          <Route path="products/:categoryName" element={<CategoryDetail />} />
          <Route path="/contact" element={<ContactPage />} />
        </Routes>
      </BrowserRouter>

    </div>
  )
}

export default App