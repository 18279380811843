import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import swal from 'sweetalert'

const CategoryCards = ({ data }) => {
    const [showPopup, setShowPopup] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        reason: 'general',
        message: ''
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        const { name, email, phone, reason, message } = formData;

        if (!name || !email || !message) {
            alert("Please fill out all required fields.");
            return;
        }

        const serviceId = "service_5mnarqp"
        const templateId = "template_p0fnfwf"
        const publicKey = "EInuoDCy7ZwqbDR6b"
        emailjs.send(serviceId, templateId, { name, email, phone, reason, message }, publicKey)
            .then((response) => {
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    reason: 'general',
                    message: ''
                });
                togglePopup()
                swal({
                    title: "Success",
                    text: "You Message Have Been Successfully, Our Team Will Contact You Soon.",
                    icon: "success",
                    customClass: {
                        popup: 'swal-center'
                    }
                });
                ;
            })
            .catch((error) => {
                alert("An error occurred while sending the email. Please try again later.");
            });
    };


    return (
        <div className="categoryCardWrapper">
            <div className='categoryCardWrap' id='#products'>
                {
                    data.map((product, index) => (
                        <div className='categoryCard' key={index}>
                            <img src={product.thumbnail} alt="" />
                            <p>{product.name}</p>
                            <button className="productGetInTouch" onClick={togglePopup}>
                                <span>Enquire Now</span>
                            </button>
                        </div>
                    ))
                }
            </div>

            {showPopup && (
                <div className="popup" onClick={togglePopup}>
                    <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                        <p>Enquiry Form</p>
                        <form >
                            <input value={formData.name} onChange={handleChange} name='name' type="text" placeholder='Your Name' />
                            <input value={formData.email} onChange={handleChange} name="email" type="email" placeholder='Your Email' />
                            <input value={formData.phone} onChange={handleChange} name='phone' type="text" placeholder='Your Phone' />
                            <div className="contact-field">
                                <select
                                    id="reason"
                                    name="reason"
                                    placeholder="Reason for Contact"
                                    value={formData.reason}
                                    onChange={handleChange}
                                >
                                    <option value="general">General Inquiry</option>
                                    <option value="support">Customer Support</option>
                                    <option value="feedback">Feedback</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>

                            <textarea value={formData.message} onChange={handleChange} name="message" id="" rows="2" placeholder='Your Message'></textarea>
                            <div className="button-group">
                                <button type="submit" className="submit-button" onClick={handleSubmit} >Submit</button>
                                <button type="button" className="cancel-button" onClick={togglePopup}>Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
}

export default CategoryCards;
